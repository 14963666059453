const state = {
  windowWidth: null, //used to access screen size changes in all component
  isMobileWindowSize: false, //to check mobile view window size
  darkMode: false, //enable Theme
  currentUser: "", //current logged in user firebase object
  userIpAddress: "", // user Ip address
  modalOpened: false,
  currentTab: "", // current selected tab in AppTopBar component
  isProduction: parseInt(localStorage.getItem("isProduction"), 10),
  apiTimeOut: 29000,
  formAccessRights: null, //Access rights for all forms
  formIdAccessRights: null,
  sideBarMenuList: [], //sidebar menu list
  isAdmin: 0, //to check user is an admin or not
  isManager: 0, //to check user is an manager or not
  // organization details all in single object
  orgDetails: {
    organizationName: "",
    orgDateFormat: "YYYY/MM/DD", //by default we have this format.Change based on org format
    assessmentYear: "", // assessment year of the organization
    paycycle: "",
    employeeId: 0, // employee id of the login user
  },
  userDetails: {
    employeeId: null,
    userDefinedEmployeeId: "",
    employeeFullName: "",
    employeeFirstName: "",
    employeePhotoPath: "",
    employeeEmail: "",
  },
  //employees search and filter form data
  empSearchValue: "",
  empFilterValue: {
    selectedDepartment: [],
    selectedDesignation: [],
    selectedEmpType: [],
    selectedLocation: [],
    selectedWorkSchedule: [],
  },
  //to check whether we are sending email of team activity tracker

  isSendEmailButtonClicked: false,

  // request access notification bar data
  showRequestAccessNotification: false,
  //regime top notification bar data
  showTaxRegimeSelection: false,
  regimeComparisonDetails: {},
  showTaxComparisonModal: false,

  //snackbar data
  showSnackbar: false,
  snackBarMessage: "",
  snackBarType: "warning",
  snackBarTimeout: 5000,

  //browser support webp
  isWebpSupport: false,

  // perfect-scrollbar settings with wheel propagation to avoid external scrolling
  scrollbarWithWheelPropagationSettings: {
    maxScrollbarLength: 60,
    wheelSpeed: 0.6,
    wheelPropagation: false,
  },
  // perfect-scrollbar settings without wheelPropagation
  scrollbarSettings: {
    maxScrollbarLength: 60,
    wheelSpeed: 0.6,
  },
  // perfect-scrollbar settings without wheelPropagation
  smallScrollbarSettings: {
    maxScrollbarLength: 30,
    wheelSpeed: 0.6,
  },
  // dashboard type chosen in plan
  planDashboardType: "",
  clearTopBarSearch: false,
  //vue forms list hard coded to route vue forms differently
  vue3Forms: [
    "Approval Management",
    "Full & Final Settlement",
    "Integration",
    "Pre Approval",
    "Pre Approvals",
    "Job Posts",
    "LOP Recovery",
    "Payroll",
    "Team Summary",
    "Provident Fund",
    "NPS",
    "Compensatory Off Balance",
    "Internet access control",
    "Timesheets",
    "Dynamic Form Builder",
    "Interview Rounds Master",
    "Job Candidates",
    "Individuals",
    "Workflow Builder",
    "Time Off Management",
    "Dashboard",
    "Org Structure",
    "Recruitment",
    "Exit Management",
    "Location Intelligence",
    "Man Power Planning",
    "Hiring Forecast",
    "Job Requisition",
    "Table of Organization",
  ],
  vue2Forms: [
    "Productivity Monitoring",
    "Activity Tracker",
    "Members",
    "Reports",
    "Shift Scheduling",
    "Salary Template",
    "Additional Wage Claim",
    "Activity Dashboard",
    "Tax and Statutory Compliance",
    "Assets",
    "Performance Management",
    "Billing",
    "ESOP",
    "Workforce Analytics",
    "DocuSign",
    "Register Face",
    "Core HR",
    "File Transfers",
    "Data Loss Prevention",
    "Vendors",
    "Accreditation",
    "Employee Data Management",
    "Performance Evaluation",
  ],
  // order should be based on tab order
  employeeDataSetupForms: [
    {
      formName: "custom-employee-groups",
      displayFormName: "Custom Group",
      url: "custom-employee-groups",
      isVue2: true,
    },
    {
      formName: "projects",
      displayFormName: "Projects",
      url: "projects",
      isVue2: false,
    },
    {
      formName: "holidays",
      displayFormName: "Holidays",
      url: "holidays",
      isVue2: true,
    },
    {
      formName: "user-accounts",
      displayFormName: "User Accounts",
      url: "user-accounts",
      isVue2: true,
    },
    {
      formName: "document-sub-type",
      displayFormName: "Document Subtype",
      url: "document-subtype",
      isVue2: false,
    },
  ],
  coreHrOrgStructureForms: [
    {
      formName: "employee-type",
      displayFormName: "Employee Type",
      url: "employee-type",
      isVue2: false,
    },
    {
      formName: "grades",
      displayFormName: "Grades",
      url: "grades",
      isVue2: false,
    },
    {
      formName: "locations",
      displayFormName: "Locations",
      url: "locations",
      isVue2: false,
    },
    {
      formName: "department-hierarchy",
      displayFormName: "Department Hierarchy",
      url: "department-hierarchy",
      isVue2: false,
    },
    {
      formName: "designations/positions",
      displayFormName: "Designations/Positions",
      url: "designations",
      isVue2: true,
    },
    {
      formName: "work-schedule",
      displayFormName: "Work Schedule",
      url: "work-schedule",
      isVue2: true,
    },
    {
      formName: "business-unit-/-cost-center",
      displayFormName: "Business Unit / Cost Center",
      url: "business-unit",
      isVue2: false,
    },
    {
      formName: "organization-group",
      displayFormName: "Organization Group",
      url: "organization-group",
      isVue2: false,
    },
  ],
  adminForms: [
    "Admin",
    "Employee Admin",
    "Payroll Admin",
    "Service Provider Admin",
    "Productivity Monitoring Admin",
  ],
  coreHrSettingsForms: [
    {
      formName: "leave",
      displayFormName: "Time Off",
      url: "time-off",
      isVue2: true,
      subForms: ["short-time-off-(permission)", "short-time-off-(on-duty)"],
    },
    {
      formName: "comp-off",
      displayFormName: "Comp Off",
      url: "comp-off",
      isVue2: false,
    },
    {
      formName: "special-wages",
      displayFormName: "Special Wages",
      url: "special-wages",
      isVue2: false,
    },
    {
      formName: "attendance-configuration",
      displayFormName: "Attendance Configuration",
      url: "attendance-configuration",
      isVue2: false,
    },
    {
      formName: "geo-fencing-&-selfie-attendance",
      displayFormName: "Geo-Fencing & Selfie Attendance",
      url: "geo-fencing-&-selfie-attendance",
      isVue2: true,
    },
    {
      formName: "holidays",
      displayFormName: "Holidays",
      url: "holidays",
      isVue2: true,
    },
    {
      formName: "pre-approvals",
      displayFormName: "Pre Approvals",
      url: "pre-approvals",
      isVue2: false,
    },
    {
      formName: "lop-recovery",
      formId: "253",
      displayFormName: "LOP Recovery",
      url: "lop-recovery",
      isVue2: false,
    },
    {
      formName: "roster",
      displayFormName: "Roster",
      url: "roster",
      isVue2: false,
    },
    {
      formName: "super-admin",
      formId: "147",
      displayFormName: "Roles",
      url: "roles",
      isVue2: false,
      accessType: "optionalChoice",
    },
  ],
  rosterManagementForm: [
    {
      formName: "shift-scheduling",
      displayFormName: "Shift Scheduling",
      url: "shift-scheduling",
      isVue2: true,
    },
    {
      formName: "shift-rotation",
      displayFormName: "Shift Rotation",
      url: "shift-rotation",
      isVue2: false,
    },
  ],
  dataLossPreventionForm: [
    {
      formName: "data-loss-prevention",
      displayFormName: "Data Loss Prevention",
      url: "data-loss-prevention",
      isVue2: true,
    },
    {
      formName: "internet-access-control",
      displayFormName: "Internet Access Control",
      url: "data-loss-prevention/internet-access-control",
      isVue2: false,
    },
    {
      formName: "location-tracking",
      displayFormName: "Location Tracking",
      url: "data-loss-prevention/location-tracking",
      isVue2: false,
    },
  ],
  partnerForms: ["Trulead"],
  // billing/payment/subscription/plan
  trialPeriod: null, // pending days of trial period
  planSubscriptionStatus: "", // it maybe none, skipSub, or subscribed
  isAutoBilling: null, // in default it was set null, after retrieving the value may be 0 or 1
  chargeBeeSiteName: "", // chargebee site names based on staging or production environment
  autoBillingPlanCurrentStatus: "", // it defines the current status of the plan
  // auto-billing plan details
  autoBillingDetails: {
    planId: "",
    subscriptionId: "",
    subscribedPlanQuantity: 0,
    subscriptionCurrency: "",
    subscriptionBillingPeriod: 0,
  },
  isIntroScreenPresented: false,
  countryList: [],
  coordinates: {},
  enableWorkflow: "No",
  //Vendor Filters
  vendorAdminFilter: {
    selectedVendorType: [],
    selectedStatusLevel: [],
    selectedServiceOffered: [],
    selectedIndustryType: [],
    selectedServicePriority: [],
    selectedStatus: [],
  },
  customFormFields: [],
  projectLabel: "Project",
};
export default state;
